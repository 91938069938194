:root {
  --primary-font: "Anek Latin", sans-serif;
  --secondary-light: #d5a95a;
}
#root {
  background: #f1ece4;
}
.primary-colors {
  color: var(--primary-colors) !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.header-top {
  transition: opacity 0.5s ease-in-out;
}
.popup-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  color: var(--primary-colors);
  z-index: 99;
  cursor: pointer;
}
.mobile-header-content-area {
  min-height: 60vh;
  position: relative;
}
.main-bar {
  transition: all ease-in-out 0.3s;
  &.show {
    position: absolute;
    top: 30px;
  }
  &.hide {
    margin-left: -8rem;
  }
}

.new-btn {
  color: white !important;
}
.order-content-heads {
  border-bottom: 1px solid var(--secondary-light) !important;
  font-weight: 300;
  color: var(--primary-colors) !important;
}
.custom-slider {
  .marks-options {
    font-size: 14px;
    color: var(--primary-colors);
    margin: 0 !important;
    padding: 0 !important;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
    p {
      font-size: 13px;
      margin: 0 !important;
      padding: 0 !important;
      line-height: 10px;
      color: var(--primary-colors);

      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
  &.rc-slider-disabled {
    background-color: transparent !important;
  }
  .rc-slider-rail {
    background-color: var(--secondary-light);
  }
  &.rc-slider-disabled .rc-slider-track {
    background-color: var(--primary-colors) !important;
  }
  .rc-slider-dot {
    height: 20px;
    width: 20px;
    bottom: -8px;
    border: none;
    background-color: var(--secondary-light) !important;
    @media screen and (max-width: 768px) {
      height: 15px;
      width: 15px;
      bottom: -6px;
    }
  }
  .rc-slider-dot-active {
    background-color: var(--primary-colors) !important;
  }
  .rc-slider-handle {
    display: none;
  }
}
.myCheckout {
  .panel-body {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #fff;
  }
}
.new-cate-slider {
  .swiper-pagination-bullet {
    border: 1px solid var(--secondary-color);
    background: transparent;
    &.swiper-pagination-bullet-active {
      border: none;
    }
  }
  .slider-new {
    position: absolute;
    bottom: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    @media screen and (max-width: 768px) {
      bottom: -3px;
    }

    .slider-arrows-new {
      width: 120px;
      @media screen and (max-width: 768px) {
        width: 160px;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .category-slider-arrow {
      // position: absolute;
      // left: 38.5rem;
      // bottom: -14.2rem;
      cursor: pointer;
      svg {
        color: var(--secondary-color);
        width: 15px;
        height: 15px;
      }
    }
  }
}

.product-details-wrap {
  max-width: 500px;
}
.accordion-body {
  .accordion-description {
    font-weight: 300 !important;
  }
}
.header-top {
  z-index: 5;
  position: sticky;
  &.search-bar {
    position: absolute;
    width: 100%;
    z-index: 0;
    background: #fff;
    top: -4rem;
    transition: all ease-in-out 0.6s;
    opacity: 1;
    visibility: visible;
    &.visible {
      top: 105px;
      z-index: 2;
    }
  }
}

.green-order {
  color: var(--primary-colors);
}
.bg-color {
  background-color: var(--primary-colors);
}
.text-color {
  color: var(--primary-colors) !important ;
}

.star-container {
  display: flex;
  align-items: center;
  @media screen and (max-width: 480px) {
    .text {
      display: none;
    }
  }
}

.adjust-proceed-btn-width {
  margin-left: 150px !important;
  @media screen and (max-width: 480px) {
    margin-left: 0 !important;
  }
}
.you-may-like {
  font-size: 29px;
  color: var(--secondary-color);
  margin-top: 2rem !important;
}
.header-top-text {
  font-size: 14px;
}
.FontCharlie {
  font-family: var(--primary-font);
}
.header-search {
  transition: opacity 0.5s ease-in-out;
}
.icon-search {
  margin-top: 5px;
}
.overflow-hidden {
  overflow: hidden !important;
}
.sticky {
  position: sticky !important;
  top: 0;
}
.comments-area p {
  font-size: 14px !important;
  margin-bottom: 0;
}
.comments-area .comment-list .single-comment {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}
.thumb-star {
  background-color: var(--primary-colors);
  color: #fff;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  font-size: 12px;
}
.new_cat_slider .card-2 {
  @media screen and (max-width: 426px) {
    height: 110px;
    width: 100px;
    min-height: 110px;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    figure {
      height: 70px;
    }
    img {
      border-radius: 50%;
    }
  }
}
.new_cat_slider .swiper-wrapper {
  @media screen and (max-width: 426px) {
    height: 130px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: -16px;
  right: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: -16px;
  left: auto;
  @media screen and (max-width: 480px) {
    display: none !important;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-button-next {
  top: 60%;
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0);
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 50%;
  color: blue;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.2s ease, color 0.2s ease;

  &::after {
    font-size: 16px;
  }
}

.myNewArrow .swiper-button-prev {
  &:after {
    position: relative;
    left: -1px;
  }
}

.myNewArrow .swiper-button-next {
  &:after {
    position: relative;
    left: 1px;
  }
}

.myNewArrow .swiper-button-prev,
.myNewArrow .swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}

.myNewArrow .swiper-button-next,
.myNewArrow .swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}

.myNewArrow .swiper-button-prev.swiper-button-disabled,
.myNewArrow .swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

/* flex */
.flex-col {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: end !important;
}
.justify-between {
  justify-content: space-between !important;
}

.flex-1 {
  flex: 1 !important;
}
.sm\:align-items-end {
  @media screen and (max-width: 480px) {
    align-items: flex-end !important;
  }
}

///////@at-root
.custom_dot_line {
  transform: rotate(90deg);
  height: 32px;
  width: 100px;
}
.dot_wrapper {
  height: 100px;
  width: 32px;
}
.dot_wrapper svg {
  height: 100px;
  width: 32px;
  margin-left: -12px;
  margin-top: 5px;
}
.otp_input {
  height: 100%;
  width: 100% !important;
  border: #adb5bd solid;
  border-radius: 5px;
}

.path-vert {
  stroke-dasharray: 10;
  /*   stroke-dashoffset: 800; */
  // animation: draw1 5s linear alternate;
}

@keyframes draw1 {
  from {
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dashoffset: 600;
  }
}
.otp_container {
  width: 55%;
}
.otp_container div {
  width: 40px;
  height: 40px;
}
.otp_container.otp_cont_2 div {
  width: 75px;
  height: 40px;
  margin: 0px 5px 0 0;
}

.otp_container input {
  padding: 0 !important;
}
.otp_focus {
  border: rgb(0, 0, 0) solid !important;
  // @media screen and (max-width:768px) {
  // border:none !important;
  // }
}
.custom_location {
  background-color: #c5ead9 !important;
  border-radius: 10px;
}
.custom_location a {
  background-color: #c5ead9 !important;
  height: 44px;
  padding: 10px !important;
}

.custom_location a img {
  margin-right: 10px !important;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.g_marker {
  position: absolute;
  width: 38px;
  height: 37px;
  background-image: url(https://icon-library.com/images/pin-icon-png/pin-icon-png-9.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: grab;
}
.g_autoComplete {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.g_googlemap {
  width: 100%;
  height: 100%;
}
.main-map-wrapper {
  height: 100vh;
  width: 100%;
  margin-bottom: 4rem;
  filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
  margin-top: 15px;
}
.map-details {
  text-align: center;
  font-size: 1.2em;
}
.map-details span {
  font-weight: bold;
}
.search-input {
  font-size: 1.2em;
  width: 80%;
}
.search_map {
  position: absolute;
  top: 10px;
  left: 10px;
  // transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}
.search_map input {
  padding: 0 15px;
  font-size: 1rem;
  width: 80%;
  background: #fff;
  height: 40px;
  border-radius: 5px;
}
.locate_map {
  position: absolute;
  top: 4rem;
  right: 9px;
  z-index: 10;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 5px;
}
.searched_list {
  padding: 1rem;
  padding-top: 5px;
  background: white;
  width: 80%;
  border-radius: 0 0 10px 10px;
  margin-top: 1px;
  z-index: 999;
}

.display-sm-block {
  display: none;
  @media screen and (max-width: 820px) {
    display: block;
  }
}
.display-sm-none {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.display-lg-none {
  display: none !important;
  @media screen and (max-width: 1200px) {
    display: block !important;
  }
}
.display-md-none {
  display: block;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}
.positioning {
  top: 11px;
  right: 10px;
}

.list_cat {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.custom-filter-bar {
  @media screen and (max-width: 1200px) {
    position: absolute;
    background: white;
    max-width: 30%;
    min-width: 300px;
    left: 0;
    top: 0;
    padding: 2rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    z-index: 99;
    display: block !important;
    max-height: 100vh;
    height: 100vh;
    overflow-y: scroll;
  }
}

.background-cover {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
    background-color: black;
    opacity: 0.3;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
}
.bg-lightGray {
  background-color: #f4f4f4;
}
.shoplist-child {
  // min-height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: auto;
}
.shoplist {
  width: 100%;
}

.price-per {
  font-size: 14px !important;
  color: #2e9d5e !important;
  font-weight: 500;
}
.form-check-input:checked {
  background-color: #1b7834 !important;
  border-color: #8aa598 !important;
}
.display-md-block {
  display: none;
  @media screen and (max-width: 1200px) {
    display: block;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.md\:flex-col {
  @media screen and (max-width: 768px) {
    flex-direction: column !important;
  }
}
.sm\:mt-1 {
  @media screen and (max-width: 480px) {
    margin-top: 1rem !important;
  }
}

.sm\:display-between {
  @media screen and (max-width: 480px) {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
}
.sm\:m-0 {
  @media screen and (max-width: 480px) {
    margin: 0 !important;
  }
}
.sm\:p-0 {
  @media screen and (max-width: 480px) {
    padding: 0px !important;
  }
}
.sm\:p-1 {
  @media screen and (max-width: 480px) {
    padding: 10px !important;
  }
}
.sm\:px-0\.5 {
  @media screen and (max-width: 480px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.sm\:px-0 {
  @media screen and (max-width: 480px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.sm\:pt-2 {
  @media screen and (max-width: 480px) {
    padding-top: 1.5rem !important;
  }
}
.sm\:p-2 {
  @media screen and (max-width: 480px) {
    padding: 1.5rem !important;
  }
}
.sm\:p-3 {
  @media screen and (max-width: 480px) {
    padding: 2rem !important;
  }
}
.sm\:d-flex {
  @media screen and (max-width: 480px) {
    display: flex !important;
  }
}
.sm\:flex-col {
  @media screen and (max-width: 480px) {
    flex-direction: column !important;
  }
}
.cart_total_label {
  @media screen and (max-width: 480px) {
    text-align: start !important;
  }
}
.sm\:w-fit {
  @media screen and (max-width: 480px) {
    width: fit-content !important;
  }
}
.install_app_container {
  @media screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.col-xs-6 {
  @media screen and (max-width: 480px) {
    width: 50% !important;
  }
}
.mob_detail_extralink .detail-qty {
  padding: 5px 7px 5px 14px !important;
  max-width: 77px !important;
  @media screen and (max-width: 480px) {
    // padding: 5px !important;
    // max-width: 100% !important;
    padding: 5px 45px 5px 20px !important;
    margin-bottom: 5px !important;
  }
}
.custom_collection_sidebar .image {
  height: 40px !important;
  width: 40px !important;
}

.floting-whatsapp-icon {
  position: fixed;
  right: 10px;
  height: 50px;
  width: 50px;
  bottom: 85px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  svg {
    font-size: 35px;
    color: #25d366;
    animation: beat 0.35s infinite alternate 2s;
    transform-origin: center;
  }
  @keyframes beat {
    to {
      transform: scale(1.2);
    }
  }
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.bg-element {
  right: 0;
  top: 0;
  width: 700px;
  height: 700px;
  z-index: -1;
  @media screen and (max-width: 768px) {
    right: -30px;
    top: -30px;
    width: 400px;
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    right: -140px;
    width: 400px;
    height: 500px;
  }
}
.header-wrapper {
  @media screen and (max-width: 480px) {
    justify-content: space-between !important;
  }
}
.page-nav-link {
  a {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    margin: 0 30px;
    &.red {
      color: rgba(255, 0, 65, 1);
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 20px;
    }
  }
}
.highlight {
  .content {
    width: 40%;
    h4 {
      font-family: "Ding";
      font-weight: 400;
      font-size: 55.5556px;
      line-height: 102%;
      letter-spacing: -0.01em;
      color: #7b0063;
      margin-bottom: 10px;
    }
    p {
      font-family: var(--primary-font);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      color: #5f5f5f;
    }
    .product-price {
      .font-head-new {
        font-family: "Ding";
        font-weight: 400;
        font-size: 38.3537px;
        @media screen and (max-width: 768px) {
          font-size: 29.9856px;
        }
        line-height: 46px;
        color: #007551;
      }
      .old-price-new {
        font-family: var(--primary-font);
        font-weight: 400;
        font-size: 20.8303px;
        @media screen and (max-width: 768px) {
          font-size: 16.2855px;
        }
        line-height: 23px;
        color: #252928;
      }
      .price-per-new {
        font-family: var(--primary-font);
        font-weight: 900;
        font-size: 17.2197px;
        line-height: 23px;
        @media screen and (max-width: 768px) {
          font-size: 13.4627px;
        }
        letter-spacing: -0.03em;
        color: #007551;
      }
    }
    .btn-custom {
      margin: 15px 0;
      background: #ff0041;
      border-radius: 7.14894px;
      width: 200px;
      height: 42px;
      @media screen and (max-width: 768px) {
        padding: 0;
      }
    }
  }
  .image-wrapper {
    width: 60%;
    height: 500px;
    img {
      object-fit: contain;
    }
    @media screen and (max-width: 768px) {
      height: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    .content {
      width: 100% !important;
      padding: 30px 10px;
      h4 {
        font-size: 43.4343px;
      }
      p {
        font-size: 13.2909px;
      }
      .btn-custom {
        width: 140px;
        height: 32.84px;
      }
    }
    .image-wrapper {
      width: 100% !important;
      // margin-bottom: 4rem;
      // img {
      //   transform: translateX(20px);
      // }
    }
  }
}
.video_banner {
  margin-top: 4rem;
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 20px;
    @media screen and (max-width: 768px) {
      font-size: 25.4425px;
    }
  }
  .react-player {
    // margin: 6rem 0;
    position: relative;
    width: fit-content;
    margin: auto;
    margin-bottom: 10rem;
    margin-top: 10rem;
    @media screen and (max-width: 768px) {
      margin-bottom: 3rem;
      margin-top: 3rem;
      width: 100%;
      height: 300px;
      .react-player-video {
        width: 100%;
        height: 100%;
        div {
          width: 100% !important;
        }
      }
    }
    .el {
      position: absolute;
      z-index: 0;
      &.el1 {
        right: -60px;
        bottom: -70px;
        @media screen and (max-width: 768px) {
          width: 81.9px;
          height: 81.9px;
          right: -30px;
          bottom: -30px;
          display: none;
        }
      }
      &.el2 {
        left: -70px;
        top: -40px;
        z-index: 2;
        @media screen and (max-width: 768px) {
          width: 71.77px;
          height: 71.77px;
          left: -30px;
          top: -20px;
          display: none;
        }
      }
      &.el3 {
        right: -65px;
        bottom: 90px;
        @media screen and (max-width: 768px) {
          width: 25.33px;
          height: 25.33px;
          right: -30px;
          bottom: 55px;
          display: none;
        }
      }
      &.el4 {
        left: -50px;
        top: 110px;
        @media screen and (max-width: 768px) {
          width: 16.89px;
          height: 16.89px;
          left: -25px;
          top: 55px;
          display: none;
        }
      }
    }
    .react-player-video {
      z-index: 1;
      iframe {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
      .react-player__preview {
        border-radius: 44.6498px;
        @media screen and (max-width: 768px) {
          // border-radius: 21.5311px;
          border-radius: 0;
        }
      }
    }
    .ytp-chrome-top {
      display: none !important;
    }
  }
}
.shortContent {
  // margin-top: 2rem;
  // margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 80px 0;
  .shortContentInner {
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .content {
    max-width: 335px;
    @media screen and (max-width: 768px) {
      justify-content: unset;
      margin-bottom: 20px;
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 15px;
      @media screen and (max-width: 768px) {
        width: 90px;
        height: 90px;
      }
    }
    h4 {
      font-family: var(--primary-font);
      font-weight: 400;
      font-size: 20.8942px;
      line-height: 23px;
      color: #ffffff;
      white-space: inherit;
    }
  }
}

.wideContent {
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    flex-direction: row-reverse;
  }
  &.right {
    flex-direction: row;
  }
  &.top {
    flex-direction: column-reverse;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 500px;
      img {
        max-height: 500px;
        object-fit: cover;
      }
    }
    @media screen and (max-width: 768px) {
      .contentImage {
        max-height: 500px;
        img {
          max-height: 500px;
        }
      }
    }
  }
  &.bottom {
    flex-direction: column;
    .content {
      width: 80%;
    }
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
  .content {
    width: 50%;
    margin: 8rem auto;
    flex-direction: column;
    display: flex;
    // align-items: center;
    justify-content: center;
    &.withImg {
      width: 50%;
      padding: 0 80px;
      @media screen and (max-width: 768px) {
        padding: 0;
        width: 90%;
      }
    }
    h4 {
      font-family: "Ding";
      font-weight: 400;
      font-size: 38.5939px;
      line-height: 116.5%;
      letter-spacing: -0.01em;
      // padding-bottom: 20px;
    }
    p {
      font-family: var(--primary-font);
      font-weight: 800;
      font-size: 17px;
      line-height: 22px;
      // text-align: center;
      color: #5f5f5f;
    }
    button {
      height: 42px;
      background: #ff0041;
      border-radius: 7.14894px;
      margin-bottom: 10px;
      margin-top: 15px;
      width: fit-content;
    }
    @media screen and (max-width: 768px) {
      margin: 4rem auto;
      width: 80%;
      h4 {
        font-size: 25.44px;
      }
      p {
        font-size: 13.2px;
      }
      button {
        font-size: 11px;
      }
    }
  }

  .contentImage {
    width: auto;
    height: auto;
    max-height: 600px;
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse !important;
    .contentImage {
      width: 100%;
      max-height: 400px;
      img {
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}

.image-grid {
  padding: 4rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 0;
    .gridItem {
      height: auto !important;
      max-height: 100% !important;
    }
  }
  .gridItem {
    // padding: 10px;
    max-height: 570px;
    height: auto;
    // margin-bottom: 10px;
    img {
      // object-fit: cover;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
.testimonials {
  margin: 0 5rem;
  @media screen and (max-width: 768px) {
    margin: 0;
    margin-top: 5rem;
  }
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      font-size: 26.6836px;
      text-align: center;
      width: 80%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .accordion-header {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 18.8867px;
    line-height: 21px;
    text-transform: capitalize;
    color: #ffffff;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
  }
  .accordion-item {
    background-color: #f4f4f4;
    margin-bottom: 10px;
  }
  .accordion-button {
    border: none;
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
  }
  .accordion-collapse {
    padding: 10px;
  }
  .accordion-button:not(.collapsed) {
    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
    }
    color: #fff;
    background-color: #7b0063;
    // .accordion-button:not(.collapsed)::after {
    //   background-image: url("../imgs/landing/icons/arrowUp.png") !important;
    // }
  }
  .accordion-body {
    font-family: var(--primary-font);
    font-weight: 400;
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 13.0582px;
    }
    line-height: 25px;
    color: #000000;
  }
}
.customerReview {
  .swiper-slide-active {
    .review-slide {
      background: rgba(123, 0, 99, 0.06);
      border-radius: 73px;
      transform: scale(1);
      @media screen and (max-width: 768px) {
        border-radius: 40px;
      }
    }
  }
  margin-top: 5rem;
  margin-bottom: 5rem;
  h4 {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
  .review-slide {
    background: rgba(244, 244, 244, 0.65);
    border-radius: 35.0962px;
    padding: 50px;
    text-align: center;
    transform: scale(0.788);
    transition: all ease-in-out 0.3s;
    img {
      width: 70px;
      height: 70px;
    }
    p {
      font-family: var(--primary-font);
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      text-align: center;
      color: #000000;
    }
    h4 {
      font-family: var(--primary-font);
      font-weight: 400;
      font-size: 22.4842px;
      line-height: 25px;
      text-transform: capitalize;
      color: #1e1e1e;
    }
    @media screen and (max-width: 768px) {
      h4 {
        font-size: 13.5px;
      }
      p {
        font-size: 13px;
      }
      .review-slide {
        padding: 20px !important;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .slider-arrow {
    display: none;
  }
}
.phonePay-div,
.gPay-div,
.payTm-div {
  margin-right: -5px !important;
}
.accordion-flush {
  margin-top: 3rem;
  .accordion-button {
    border: 1px solid var(--secondary-light);
    border-left: unset !important;
    border-right: unset !important;
  }

  .accordion-one {
    border-top: 1px solid var(--secondary-light) !important;
    border-left: unset !important;
    border-right: unset !important;
  }
  .accordion-three {
    border-bottom: 1px solid var(--secondary-light) !important;
    border-left: unset !important;
    border-right: unset !important;
    border-left: unset !important;
    border-top: unset !important;
  }
  .accordion-two {
    border-top: unset !important;
  }
  .icon-accordion {
    color: var(--primary-colors);
    font-size: 20px;
    margin-right: 1rem;
  }
  .text-accordion {
    color: var(--primary-colors);
    font-size: 16px;
    padding-left: 13px;
  }
  .accordion-description {
    font-size: 16px;
    color: var(--primary-colors);
    font-weight: 400;
  }
  .accordion-button:not(.collapsed) {
    background-color: unset !important;
  }
}

.customer-review {
  .customer-review-stars {
    color: var(--secondary-light);
  }
  .text-review-customer {
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 400;
  }
  .be-first {
    font-size: 15px;
    color: black;
    font-weight: 400;
  }
  .btn-div-review {
    // margin-right: 4rem;
    .review-btn-customer {
      border-radius: 10px;
      color: var(--primary-colors);
      border: 1px solid var(--secondary-light);
      padding: 5px 15px;
    }
  }
  .input-review {
    border: 1px solid var(--secondary-light);
    border-radius: unset;
    background-color: white;
    height: 36px;
    font-size: 14px;
  }
  .label-customer-review {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
  }
  .customer-rating-text {
    font-size: 12px;
    color: #000000;
    font-weight: 400;
  }
  .review-submit-btn {
    font-weight: 400 !important;
    padding: 10px 24px !important;
    background-color: var(--secondary-color);
    border: unset;
  }
  .reviewImage {
    object-fit: cover;
    width: 100px;
    height: 100px;
  }
}

.heading-cart {
  font-size: 35px;
  font-weight: 400;
  color: var(--primary-colors);
}
.continue-shopping-cat-text {
  font-size: 15px !important;
  text-decoration: underline;
  color: var(--primary-colors);
  font-weight: 400;
}
.product-name {
  font-size: 18px;
  color: var(--primary-colors);
}
.price-cart,
.cart-item-color {
  font-size: 17px !important;
  color: var(--primary-colors);
}
//checkout
.Checkout {
  .track-order-text {
    font-size: 33px;
    color: var(--primary-colors);
    font-weight: 500;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    @media screen and (max-width: 428px) {
      font-size: 25px;
      padding-top: 20px;
    }
  }
  .track-div {
    border: 1px solid var(--secondary-light);
    border-radius: 10px;
    max-width: 800px;
  }
  .form-group {
    margin-bottom: 0px !important;
  }
  .track-submit {
    background-color: unset !important;
    color: var(--secondary-light);
    height: 48px;
    display: flex;
    padding: 15px 28px;
    align-items: center;
    text-align: center;
  }
  .track-input {
    border: 1px solid var(--secondary-light);
    // width: 650px;
  }
  .order-status-1st-row {
    min-width: 150px;
    font-size: 13px;
    font-weight: 400;
  }
  .order-status-2st-row {
    font-size: 13px;
    font-weight: 400;
  }
  .order-details-text {
    font-size: 15px;
  }
  .line-through-track {
    width: 100%;
    height: 40px;
    background-color: #eee5d6;
    --bs-gutter-x: 0rem !important;
    margin-top: 2rem;
    display: flex;
    align-content: center;
  }
  .icon-track {
    font-size: 20px;
  }
  .shipping-to-track {
    font-size: 13px;
    font-weight: 400;
  }
  .address-track {
    font-size: 13px;
    font-weight: 400;
  }
  .track-table {
    margin-top: 5rem;
  }
  .track-table-thead {
    // border-bottom: 2px solid var(--secondary-light) !important;
  }
  .table > :not(caption) > * > * {
    box-shadow: unset !important;
  }
  .return-btn {
    border-radius: 10px;
    border: 1px solid var(--secondary-light);
    color: var(--primary-colors);
    padding: 9px 20px;
  }
  .greenColor {
    color: var(--primary-colors);
  }
  .track-shipping-details {
    font-size: 16px;
    font-weight: 400;
  }
  .track-update {
    font-size: 12px;
    font-weight: 400;
    align-items: center !important;
  }
  .track-shipping {
    font-size: 17px;
  }
  .text-track-same {
    font-size: 12px;
    font-weight: 400;
  }
}
.custom-bg {
  background-color: unset !important;
}
.modal-content {
  background-color: white;
}
.custom-rounded-start {
  border-top-left-radius: 10px; /* Adjust the radius as needed */
  border-bottom-left-radius: 10px; /* Adjust the radius as needed */
}
.fz-12 {
  font-size: 16px;
  font-weight: 600;
}
.footer-top {
  .footer-Top-first-row {
    padding-top: 7rem;
    padding-bottom: 7rem;
    color: white;
    background-size: cover;
    background-position-y: center;
    .footer-top-main-text {
      color: white;
      font-weight: 500;
    }
    .footer-top-banner-text {
      color: white;
      font-weight: 400;
    }
    .footer-top-btn {
      color: white;
      border: none !important;
      background-color: var(--secondary-light);
      border-radius: 8px;
      padding: 4px 10px;
    }
  }
  .footer-Top-second-row {
    --bs-gutter-x: 0rem !important;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .footer-bottom-subscribe {
      font-size: 32px;
      font-weight: 500;
      color: var(--secondary-color);
    }
    .footer-bottom-p {
      font-size: 10px;
      color: var(--secondary-color);
    }
    .input-with-icon {
      position: relative;
      width: 300px; // Set the desired width

      input {
        padding-right: 30px; // Ensure enough space for the icon
        // margin-right: 8px; // Adjust the right margin as needed
        width: 100%; // Take up the full width of the container
        height: 30px;
        border: 1px solid var(--secondary-light);
        &::placeholder {
          font-size: 14px;
        }
      }

      .arrow-icon {
        position: absolute;
        top: 50%;
        right: 27px; // Adjust the right position as needed
        transform: translateY(-50%);
        color: #555; // Adjust the color as needed
        cursor: pointer;
      }
    }
  }
}
.Review {
  background-color: rgb(241, 236, 228);

  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  .review-heading {
    font-size: 30px;
    font-weight: 400;
    color: var(--secondary-color);
  }
  .review-p {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    color: #000000;
  }
  .review-card-row {
    margin-left: 7rem;
    margin-right: 7rem;
    .review-card {
      border: none !important;
      background-color: unset !important;
      .review-content-one {
        padding-top: 7px;
        padding-bottom: 7px;
        background-color: white;
        border-radius: 10px;
        // padding-left: 5px;
        // padding-right: 5px;
        .yellow-stars-review {
          color: var(--secondary-light);
        }
        .review-text {
          font-size: 18px;
          font-weight: 400;
          color: black;
        }
      }
      .review-content-two {
        .review-name {
          color: black;
          font-size: 22px;
          font-weight: 500;
        }
        .review-date {
          color: black;
          font-size: 12px;
          font-weight: 500;
        }
      }
      .review-img {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
      .review-content-three {
        .review-product {
          font-size: 16px;
          color: black;
          font-weight: 500;
        }
      }
    }
  }
}
.custom-modal .modal-dialog .btn-close {
  position: absolute;
  right: 30px;
  top: 5%;
  z-index: 2;
  // @media screen and (max-width: 428px) {
  //   top: 43%;
  // }
}
@media screen and (max-width: 428px) {
  .adjust-proceed-btn-width {
    // margin-left: 50px !important;
  }
  .footer-Top-second-row {
    --bs-gutter-x: 0rem !important;
  }
  .you-may-like {
    font-size: 20px;
  }
  .cart-mobile {
    .heading-cart {
      font-size: 19px !important;
    }
    .continue-shopping-cat-text {
      font-size: 11px !important;
    }
    .cart-heading-div {
      justify-content: space-between;
    }
    .cat-order-special-instruction-text {
      font-size: 13px !important  ;
    }
    .instruction-div-cart {
      width: auto;
      // margin-top: 0.5rem;
      // width: 155px;
      // min-height: 72px;
      // margin-left: 1rem;
    }
  }
  .detail-extralink {
    .detail-qty {
      padding: 0px 10px !important;
      min-width: 125px !important;
      min-height: 35px !important;
    }
  }
  .Checkout {
    .form-group input {
      height: 31px !important;
      font-size: 9px !important;
    }
    .track-submit {
      height: 31px;
      padding: 2px 9px;
    }
    button.submit,
    button[type="submit"] {
      font-size: 9px;
    }
    .track-div {
      width: 320px !important;
      padding: 4px !important;
    }
    .order-details-text {
      margin-top: 1rem;
    }
    // .track-order-text {
    //   margin-bottom: 1rem !important;
    //   font-size: 31px !important;
    // }

    .order-status-2st-row {
      font-size: 12px !important;
    }
    .order-status-1st-row {
      font-size: 12px !important;
    }
    .line-through-track {
      height: 20px !important;
    }
    .shipping-to-track,
    .address-track {
      font-size: 12px !important;
    }
    .return-btn {
      padding: 0px 9px;
      line-height: 18px;
      font-size: 12px;
      align-items: center;
      display: flex;
    }
    .rtn-icon {
      margin-right: 8px;
    }
    .img-track {
      width: 70px !important;
      height: 70px !important;
    }
    .text-track-same {
      font-size: 12px !important;
    }
    .track-shipping-details {
      font-size: 12px;
    }
    .table thead {
      display: table-header-group !important;
    }
  }

  .footer-top .footer-Top-first-row {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .footer-top .footer-Top-first-row .footer-top-main-text {
    font-size: 35px;
  }
  .footer-top .footer-Top-first-row .footer-top-banner-text {
    font-size: 14px;
  }
  .footer-top .footer-Top-first-row .footer-top-btn {
    padding: 2px 8px;
    font-size: 10px;
  }
  .footer {
    margin-top: unset !important;
  }
  .footer-top .footer-Top-second-row .footer-bottom-subscribe {
    font-size: 24px !important;
  }
  .footer-top .footer-Top-second-row .footer-bottom-p {
    font-size: 8px;
  }
  .footer-top .footer-Top-second-row .input-with-icon {
    width: 230px !important;
  }
  .footer-top .footer-Top-second-row .input-with-icon input {
    height: 30px;
    &::placeholder {
      font-size: 12px;
    }
  }
  .footer-logo-div {
    justify-content: center !important;
  }
  .footer img {
    width: 123px !important;
    height: 140px !important;
  }
  .footer-main-section-div {
    flex-direction: column !important;
  }
  .footer {
    padding: 17px 30px !important;
  }
  .footer img.logo-footer {
    margin-left: unset !important;
  }
  .footer .footer-link-widget h3 {
    font-size: 18.8193px !important;
  }
  .footer .footer-link-widget ul li a {
    font-size: 11px !important;
    line-height: 143.3% !important;
  }
  .footer p {
    font-size: 12px !important;
  }
  .footer-bottom {
    padding: 1rem !important;
  }
  .animate__animated {
    width: 100% !important;
  }
}
.social-icon-footer,
.footer-text {
  color: var(--primary-colors);
  font-size: 12px !important;
}
.footer-bottom {
  background-color: var(--secondary-light);
  padding: 2rem;
  border-top: 1px solid var(--primary-colors) !important;
}
.footer {
  // margin-top: 3rem;
  background-color: var(--secondary-light);
  // color: #fff;

  padding: 80px 10rem;

  img {
    width: 170px;
    height: 188px;
    object-fit: cover;
  }
  p {
    // font-family: var(--primary-font);
    font-weight: 300;
    font-size: 14px;
    line-height: 161.3%;
    color: var(--primary-colors);
  }

  .footer-link-widget {
    h3 {
      // font-family: var(--primary-font);
      font-weight: 500;
      font-size: 22px;
      line-height: 27px;
      color: var(--primary-colors);
    }
    ul {
      li {
        a {
          // font-family: var(--primary-font);
          font-weight: 400;
          font-size: 14px;
          line-height: 153.3%;
          color: var(--primary-colors);
        }
      }
    }
  }
  .mobile-social-icon a {
    background-color: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media screen and (max-width: 768px) {
    padding: 80px 30px;
    .logos {
      flex-direction: column;
      margin-bottom: 40px;
      align-items: flex-start !important;
    }
    p {
      width: 100%;
    }
    img {
      width: 130px;
      height: 92px;
      &.logo-footer {
        margin-left: -15px;
      }
    }
    .footer-link-widget {
      h3 {
        font-size: 24.8193px;
      }
    }
  }
}

.tada-animation {
  animation-name: tada;
  animation-duration: 2s;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.related-product-wrap {
  .section-title {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin-bottom: 20px;
  }
}

.mobileCarousel {
  @media screen and (max-width: 768px) {
    .mobileContainer {
      margin: 0 !important;
      border-radius: 0 !important;

      .mobileBanner {
        border-radius: 0 !important;
        height: 100% !important;
        margin-bottom: 0 !important;
      }
    }
  }
}

.section-wrapper {
  &.web {
    display: unset;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media screen and (max-width: 768px) {
      display: unset;
    }
  }
  &.common {
  }
}
.related-product-wrap {
  .related-head {
    font-family: "Ding";
    font-weight: 400;
    font-size: 38.5939px;
    line-height: 116.5%;
    letter-spacing: -0.01em;
    color: #7b0063;
    margin: 0 8rem;
    margin-bottom: 5rem;
    @media screen and (max-width: 768px) {
      font-size: 38.5939px;
      text-align: center;
      width: 90%;
      margin: auto;
      margin-bottom: 30px;
    }
  }
}

.custom-cart-button-group {
  border: 1px solid #dadee2;
  min-height: 150px;
  height: auto;
  border-radius: 20px;
  background-color: #f4f6fa;
  // padding: 1rem;
  margin-bottom: 2rem;
  .chat-w-exp {
    border-radius: 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
  }
  .buttons {
    border-bottom: 1px solid #dadee2;
  }
  .content {
    display: flex;
    justify-content: space-between;
    .border-r-1 {
      border-right: 1px solid #dadee2;
    }
  }
}

.footerAddress {
  margin-left: 3rem;
  @media screen and (max-width: 768px) {
    margin: 0 !important;
  }
}

.delete-modal {
  .modal-content {
    background-clip: unset;
  }
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}

.modal-scroll-div {
  max-height: 630px;
  overflow-y: scroll;
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 8px;
  //   background-color: #95a5a6;
  //   border: 1px solid #cacaca;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 8px;
  //   background-color: var(--primary-colors);
  // }
  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 80px);
    height: calc(90vh - 80px);
  }
}

.cod-confirm-modal-image {
  height: 50px;
  width: 50px;
}
.popup-confirm-button {
  min-width: 300px;
  @media screen and (max-width: 768px) {
    min-width: 65%;
  }
}

.checkout-btn {
  color: white;
  background-color: var(--secondary-light);
  border: none;
  border-radius: 10px;
  width: 40%;
  padding: 10px 18px;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  &:hover {
    background-color: #a98645;
  }
}
.checkout-btn.checkout-btn-product-detail {
  width: 100%;
}
// .header-action-2 .header-action-icon-2:last-child {
//   padding: 0 0 0 0px;
//   @media screen and (min-width: 400px) {
//     // padding: 0 0 0 30px;
//   }
// }
.cat-slider {
  border-radius: 50px !important;
}
.outofstock {
  background-color: #d5a95a;
  color: white;
  border-radius: 50px;
  padding: 5px 12px 5px 12px;
}
